<template>
    <div class="filter-buttons" ref="box">
        <div class="button-filter u-only-mobile" id="mobile-filter-button">
            <a><span class="button-filter-text" @click="openMenu">{{ currentFilterName }}</span>&nbsp;&nbsp;<i class="fas fa-chevron-down"></i></a>
        </div>
        <div class="button-row button-row-primary" v-if="!mobileMode">
            <div class="button-row--button" :class="{'u-active-menu-item': filter === 1}">
                <a @click="changeFilter(1)">All</a>
            </div>
            <div class="button-row--button" :class="{'u-active-menu-item': filter === 100000000}">
                <a @click="changeFilter(100000000)">Must Read</a>
            </div>
            <div class="button-row--button" :class="{'u-active-menu-item': filter === 100000010}">
                <a @click="changeFilter(100000010)">Central Banks</a>
            </div>
            <div class="button-row--button" :class="{'u-active-menu-item': filter === 100000001}">
                <a @click="changeFilter(100000001)">Trade Ideas</a>
            </div>
            <div class="button-row--button" :class="{'u-active-menu-item': filter === 100000002}">
                <a @click="changeFilter(100000002)">Research & Analysis</a>
            </div>
            <div class="button-row--button" :class="{'u-active-menu-item': filter === 100000004}">
                <a @click="changeFilter(100000004)">Video Commentary</a>
            </div>
        </div>

        <vue-slide-up-down v-if="mobileMode" :active="menuIsOpen" :duration="250" class="vue-slide-up-down">
            <div class="button-row button-row-primary">
                <div class="button-row--button" :class="{'u-active-menu-item': filter === 1}">
                    <a @click="changeFilter(1)">All</a>
                </div>
                <div class="button-row--button button-row--button-yellow u-only-mobile" :class="{'u-active-menu-item-yellow': filter === 100000011}">
                    <a @click="changeFilter(100000011)">Giles' Trades</a><img id="promo-30-day-logo-desktop" src="../../../../assets/ICON_MASTERCLASS_120x120px.png">
                </div>
                <div class="button-row--button button-row--button-yellow u-only-mobile" :class="{'u-active-menu-item-yellow': filter === 100000012}">
                    <a @click="changeFilter(100000012)">Arno's Trades</a><img id="promo-30-day-logo-desktop" src="../../../../assets/ICON_MASTERCLASS_120x120px.png">
                </div>
                <div class="button-row--button button-row--button-yellow u-only-mobile" :class="{'u-active-menu-item-yellow': filter === 100000013}">
                    <a @click="changeFilter(100000013)">Dan's Trades</a><img id="promo-30-day-logo-desktop" src="../../../../assets/ICON_MASTERCLASS_120x120px.png">
                </div>
                <div class="button-row--button" :class="{'u-active-menu-item': filter === 100000000}">
                    <a @click="changeFilter(100000000)">Must Read</a>
                </div>
                <div class="button-row--button" :class="{'u-active-menu-item': filter === 100000010}">
                    <a @click="changeFilter(100000010)">Central Banks</a>
                </div>
                <div class="button-row--button" :class="{'u-active-menu-item': filter === 100000001}">
                    <a @click="changeFilter(100000001)">Trade Ideas</a>
                </div>
                <div class="button-row--button" :class="{'u-active-menu-item': filter === 100000002}">
                    <a @click="changeFilter(100000002)">Research & Analysis</a>
                </div>
                <div class="button-row--button" :class="{'u-active-menu-item': filter === 100000004}">
                    <a @click="changeFilter(100000004)">Video Commentary</a>
                </div>
            </div>
        </vue-slide-up-down>
        

        <!--<div class="button-row button-row-secondary u-not-mobile">
        </div>-->
        <div class="button-row button-row-secondary u-not-mobile">
            <img id="promo-30-day-logo-desktop" src="../../../../assets/ICON_MASTERCLASS_120x120px.png">
            <div class="button-row--button green" :class="{'u-active-menu-item-yellow': filter === 100000011}">
                <a @click="changeFilter(100000011)">Giles' Trades</a>
            </div>
            <div class="button-row--button green" :class="{'u-active-menu-item-yellow': filter === 100000012}">
                <a @click="changeFilter(100000012)">Arno's Trades</a>
            </div>
            <div class="button-row--button green" :class="{'u-active-menu-item-yellow': filter === 100000013}">
                <a @click="changeFilter(100000013)">Dan's Trades</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        currentFilterName() {
            const obj = {
                1: "All News",
                100000000: "Must Read",
                100000010: "Central Banks",
                100000001: "Trade Ideas",
                100000002: "Research & Analysis",
                100000004: "Video Commentary",
                100000011: "Giles' Trades",
                100000012: "Arno's Trades",
                100000013: "Dan's Trades"
            }

            return obj[this.filter];
        }
    },
    data() {
        return {
            menuIsOpen: false,
            mobileMode: false,
            filter: 1
        }
    },
    mounted() {
        this.interval = setInterval(() => this.mobileMode = this.$refs.box.getBoundingClientRect().width < 950, 1000);
    },
    beforeDestroy() {
        clearInterval(this.interval);
    },
    methods: {
        openMenu() {
            this.menuIsOpen = !this.menuIsOpen;
        },
        changeFilter(id) {
            this.menuIsOpen = false;
            this.filter = id;
        }
    }
}
</script>

<style lang="scss" scoped>
    .filter-buttons {
        grid-column: 1 / -1;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        padding: 0 5px;
        margin-top: 20px;

        .button-filter {
            grid-column: 1 / -1;
            height: 40px;
            color: $color-15;
            text-align: center;
            border: 1px solid rgba($color-20, 0.12);
            font-size: 14px;
            text-transform: uppercase;
            overflow: hidden;

            & a {
                display: block;
                line-height: 40px;
                cursor: pointer;
                transition: all 0.2s ease;
                color: $color-20;

                & i.fas.rotated {
                    transform: rotate(180deg);
                }
            }
        }
        .button-row {
        
            grid-column: 1 / -1;
            // display: flex;
            // margin: 30px 0 10px 0;
            // justify-content: space-between;
            // height: 30px;
        
            // @include respond(desktop) {
                
            // }

            // @include respond(xl) {
            //     display: flex;
            //     margin: 3rem 0 1rem 0;
            //     justify-content: space-between;
            //     height: 4rem;
            // }
        
            // @include respond(mobile) {
            //     height: 0px;
            //     transition: all 0.2s ease;
            //     overflow: hidden;
            // }
        
            &.u-button-row-on {
        
                @include respond(mobile) {
                    height: 100%;
                }
            }
            
            
            .size-md & > div {
        
                
                flex-grow: 0;
                flex-shrink: 0;
                flex-basis: 16%;
              
        
            }

            &.button-row-primary {
                & div:nth-child(2),
                & div:nth-child(3),
                & div:nth-child(4) {

                    position: relative;

                    & img {
                        
                        position: absolute;
                        //FMC:
                        top: 0px;
                        height: 40px;
                        left: 20%;
                        //30DC:
                        // top: -6px;
                        // height: 50px;

                        @include respond(phone) {
                            left: 10%;
                        }

                        @include respond(tablet) {
                            left: 30%;
                        }
                        
                    }
                }

                & .button-row--button-yellow:hover {
                    background-color: $color-155;
                }
            }

            &.button-row-secondary {
                

                position: relative;
                justify-content: center;
                margin-top: 1.5rem;

                    
        

                & img {
                    position: absolute;

                    height: 65px;
                    top: -18px;
                    left: 13%;
        
                }

                & .button-row--button {

                 
                    border: 1px solid $color-15;
                    margin-left: 5px;
                    margin-right: 5px;

                  

                    &:hover {
                        background-color: $color-155;
                    }


                    // &.green {
                    //     @include respond(desktop) {
                    //         border: 1px solid $color-131;
                    //         margin-left: 0.5rem;
                    //         margin-right: 0.5rem;
        
                    //     }
        
                    //     &:hover {
                    //         background-color: $color-131;
                    //     }
                    // }

                }
                
            }
            
            &--button {
                text-align: center;
                border: 1px solid rgba($color-20, 0.12);
                text-transform: uppercase;
                overflow: hidden;
                font-size: 12px;

                // @include respond(desktop) {
                //     font-size: 1.2rem;
                // }
        
                // @include respond(xl) {
                //     font-size: 1.4rem;
                // }
            
                // @include respond(mobile) {
                //     font-size: 1.4rem;
                // }
        
                & a {
                    display: block;
                    line-height: 30px;
                    cursor: pointer;
                    color: $color-20;

                    // @include respond(desktop) {
                    //     line-height: 3rem;
                    // }
            
                    // @include respond(xl) {
                    //     line-height: 4rem;
                    // }
                
                    // @include respond(mobile) {
                    //     line-height: 4rem;
                    // }

                }
        
                &:hover {
                    background-color: $color-03;
                }
            }
        }

        

    }

    .vue-slide-up-down {
        grid-column: 1 / -1;
    }

    .size-xs .button-row {
        display: block;
    }

    .size-md .button-row.button-row-primary {
        display: flex;
        justify-content: space-between;
        margin: 30px 0 10px 0;
        height: 30px;
    }

    .size-xs .u-not-mobile {
        display: none;
    }

    .size-md .u-not-mobile {
        display: flex;
    }

    .size-md .u-only-mobile {
        display: none;
    }
</style>